









































































































































import { useCart } from '~/modules/checkout/composables/useCart';
import priceBox from '~/components/HomePage/priceBox.vue';
import {  
  SfLink
} from '@storefront-ui/vue';
import { useRouter, defineComponent, ref, useContext, onMounted, useFetch, computed } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'customProductCard',
  components: { priceBox, SfLink },
  props: {
    new_products: {
      type: Object,
      required: true,
      default: [],
    },
    json_type: {
      type: String,
      required: false,
      default: "1",
    }    
  },
  setup() {
    const context = useContext();
    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    const { addItem, error: cartError, addedtocart_id: addedtocart_id, loading: isCartLoading, canAddToCart } = useCart();

    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const add_type_product = (product) => {
      product.__typename = product.typename;
      return product;
    }

    const router = useRouter();

    const handleLinkClick = (event,url) => {
      if(event.target.tagName === 'BUTTON' || event.target.tagName === 'SPAN'){

      }else{
        router.push(url);
      }      
    }
    const limit_title = (title) => {
      var count = 30;
      title = title.slice(0, count) + (title.length > count ? "..." : "");      
      return title;
    }

    const prod_img_url = (url) => {
      var u = url.replace(magentoBaseUrl+'/media/catalog/product', '');
      if (u.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    return {
      handleLinkClick,
      limit_title,
      addItem,
      add_type_product,
      addToCartError,
      isCartLoading,
      addedtocart_id,
      prod_img_url
    };
  }
});
