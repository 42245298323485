var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner_main_container"},[_c('link',{attrs:{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css"}}),_vm._v(" "),_c('div',{staticClass:"banner_height_placeholder",style:((_vm.loading)?'':'position:absolute;left:0;top:0;width:100%;')},[_c('div',{staticClass:"bnr"},[_c('SkeletonLoader',{attrs:{"height":"555px","margin":"0px"}})],1)]),_vm._v(" "),(_vm.all_data)?_c('div',{staticClass:"bnr"},[_c('client-only',[_c('vue-tiny-slider',_vm._b({},'vue-tiny-slider',{
      items: 1,
      slideBy: 'page',
      autoplay: false,
      mouseDrag: true,
      prevButton: '.cust-owl-prev',
      nextButton: '.cust-owl-next',
      nav: false,
      onInit: _vm.initFun
    },false),_vm._l((_vm.get_slider_by_title(_vm.all_data.homeSection[0].sliders, 'Homepage Left Banner')),function(sliders_1,i){return _c('div',{key:i},[_c('a',{attrs:{"href":sliders_1.url,"title":sliders_1.title}},[_c('img',{staticClass:"home-banner-img",class:(i == 0)?"first-image":"",attrs:{"src":sliders_1.image_url,"alt":sliders_1.title}})])])}),0)],1),_vm._v(" "),_c('div',{staticClass:"cust-owl-nav",style:((_vm.loading)? "display:none;":"")},[_vm._m(0),_vm._v(" "),_vm._m(1)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bnr"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cust-owl-prev"},[_c('i',{staticClass:"fa fa-chevron-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cust-owl-next"},[_c('i',{staticClass:"fa fa-chevron-right"})])}]

export { render, staticRenderFns }